// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/redux/slice/users-slice.ts"
);
import.meta.hot.lastModified = "1725982852000";
}
// REMIX HMR END

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Device, HomeDTO, OnboardingUrlResponseDTO } from "~/api/user-types";

export interface Users {
  user: string | undefined;
  homes: HomeDTO[] | undefined;
  thermostats: Device[] | undefined;
  onboardingUrl: OnboardingUrlResponseDTO | undefined;
  onboardingUpdateUrl: OnboardingUrlResponseDTO | undefined;
}

const initialState: Users = {
  user: undefined,
  homes: undefined,
  thermostats: undefined,
  onboardingUrl: undefined,
  onboardingUpdateUrl: undefined,
};

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setHomes: (state, action: PayloadAction<HomeDTO[]>) => {
      state.homes = action.payload;
    },
    setThermostats: (state, action: PayloadAction<Device[]>) => {
      state.thermostats = action.payload;
    },
    setOnboardingUrl: (
      state,
      action: PayloadAction<OnboardingUrlResponseDTO | undefined>,
    ) => {
      state.onboardingUrl = action.payload;
    },
    setOnboardingUpdateUrl: (
      state,
      action: PayloadAction<OnboardingUrlResponseDTO | undefined>,
    ) => {
      state.onboardingUpdateUrl = action.payload;
    },
    resetUsers: () => initialState,
  },
});

export const {
  setUser,
  setHomes,
  setThermostats,
  setOnboardingUrl,
  setOnboardingUpdateUrl,
} = UsersSlice.actions;

export default UsersSlice.reducer;
